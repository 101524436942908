import React from 'react';
import { createBrowserRouter } from "react-router-dom";
import Home from "./component/page/home/Home";
import Contact from "./component/page/contact/Contact";
import RDV from "./component/page/rdv/RDV";
import WhoAmI from "./component/page/who-am-i/Who-am-i";
import Blog from "./component/page/blog/Blog";
import articles from "./component/page/blog/router";

export default createBrowserRouter([
    {
        path: "/",
        element: <Home />,
    },
    {
        path: "/contact",
        element: <Contact />,
    },
    {
        path: "/rdv",
        element: <RDV />,
    },
    {
        path: "/qui-suis-je",
        element: <WhoAmI />,
    },
    {
        path: "/blog",
        element: <Blog/>,
    },
    /*
    TODO : Confirmation page with this code :
      gtag('event', 'ads_conversion_Prise_de_rendez_vous_1', {});
     */
    ...articles
]);